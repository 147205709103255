import { useDelete, usePermissions } from '@cs/hooks';
import { RoutesName } from '@cs/state/constants';
import { SCOPES } from '@cs/state/model';
import { removeClient, undoRemoveClient } from '@cs/state/mutations';
import {
  FlexContainer,
  Icon,
  Label,
  OptionMenu,
  OptionMenuItem,
  PositionX,
  Status,
  useTimezone,
  CountryFlags,
  CountryCode,
} from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { ClientIdSection } from '../clientId';
import { Image } from '../common';
import { ClientsGridCardProps } from './model';
import {
  ClientsGridCardActions,
  ClientsGridCardContent,
  ClientsGridCardFooter,
  ClientsGridCardLogoWrapper,
  ClientsGridCardStyles,
} from './Styles';

export const ClientsGridCard = ({ client, testId }: ClientsGridCardProps) => {
  const { t } = useTranslation();
  const { formatTimezone } = useTimezone();
  const navigate = useNavigate();
  const { handleDelete } = useDelete(removeClient, undoRemoveClient, 'clients');

  const { hasPermission } = usePermissions();

  const goToClient = (clientId: string) => {
    navigate(
      generatePath(RoutesName.clientId, {
        id: clientId,
        type: client.platform
          ? ClientIdSection.users
          : ClientIdSection.licenses,
      }),
    );
  };

  const goToProfile = (clientId: string) => {
    navigate(
      generatePath(RoutesName.clientProfileId, {
        id: clientId,
      }),
    );
  };

  return (
    <ClientsGridCardStyles
      testId={`client-${testId}`}
      onClick={() => goToClient(client.id)}
    >
      <ClientsGridCardLogoWrapper>
        <Image
          id={client.avatar}
          defaultImage={'/Avatar.png'}
          alt={client.name}
        />
      </ClientsGridCardLogoWrapper>

      <OptionMenu
        hidePanelOnClick
        widthAuto
        renderItem={
          <ClientsGridCardActions variant="text" iconName="ListPlus" />
        }
        positionX={PositionX.end}
        testId={`option-menu-client-${testId}`}
      >
        <OptionMenuItem
          testId="button-view-edit"
          onClick={(event) => {
            goToProfile(client.id);
            event.stopPropagation();
          }}
          disabled={hasPermission([SCOPES.canEditClients]) ? false : true}
          iconName="Pencil"
        >
          {t('Edit')}
        </OptionMenuItem>

        <OptionMenuItem
          testId="button-view-client"
          onClick={(event) => {
            goToClient(client.id);
            event.stopPropagation();
          }}
          iconName="MagnifyingGlassPlus"
        >
          {t('View more')}
        </OptionMenuItem>

        <OptionMenuItem
          testId="button-delete-client"
          onClick={(event) => {
            handleDelete(client.id);
            event.stopPropagation();
          }}
          disabled={hasPermission([SCOPES.canDeleteClients]) ? false : true}
          color="error"
          iconName="Trash"
        >
          {t('Delete')}
        </OptionMenuItem>
      </OptionMenu>

      <ClientsGridCardContent flexDirection="column" rowGap="1.6">
        <FlexContainer alignItems="center" columnGap="1">
          {client.country && (
            <CountryFlags
              countryCode={client.country as CountryCode}
              size="16"
            />
          )}
          <Label fontSize="16" semibold>
            {client.name}
          </Label>
        </FlexContainer>

        <Label fontSize="12">{client.description}</Label>

        {client.platform && (
          <>
            <FlexContainer columnGap="0.8" alignItems="center">
              <Icon iconName="UsersThree" size="18" />
              <Label fontSize="12" semibold>
                {client.users_aggregate.aggregate.count || 0} {t('users')}
              </Label>
            </FlexContainer>

            <FlexContainer columnGap="0.8" alignItems="center">
              <Icon iconName="GlobeHemisphereWest" size="18" />
              <Label fontSize="12" semibold>
                {client.tenants_aggregate.aggregate.count || 0} {t('tenants')}
              </Label>
            </FlexContainer>
          </>
        )}
      </ClientsGridCardContent>

      <ClientsGridCardFooter
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        rowGap="0.6"
      >
        <FlexContainer columnGap="0.6">
          <Status status={client.active ? 'SUCCEEDED' : 'DENIED'} />
          <Label fontSize="10" semibold>
            {client.active ? t('Active') : t('Inactive')}
          </Label>
        </FlexContainer>

        <Label fontSize="10" semibold>
          {formatTimezone(client.created_at)}
        </Label>
      </ClientsGridCardFooter>
    </ClientsGridCardStyles>
  );
};
