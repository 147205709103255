import { useEffect } from 'react';
import { ModalCreateUserProps, User } from '@cs/state/model';
import { FlexContainer, Input, Label } from '@facephi/ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EMAIL, FIRSTNAME, LASTNAME, PASSWORD } from '../../state/constants';
import { createUserSchema } from '../../state/model';
import { ModalCreateUserStyles } from './Styles';

export const ModalCreateUser = ({
  className,
  onSave,
  onChangeShow,
  show,
}: ModalCreateUserProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(createUserSchema),
    mode: 'onChange',
  });

  const onSubmit = (newUser: User) => {
    if (newUser) {
      onSave(newUser);
    }
  };

  useEffect(() => {
    if (show) {
      reset({});
    }
  }, [show]);

  return (
    <ModalCreateUserStyles
      show={show}
      testId="modal-create-user"
      header={{
        iconName: 'PlusCircle',
        title: t('Create a new user'),
      }}
      footer={{
        closeButtonLabel: t('Cancel'),
        submitButtonLabel: t('Save'),
        onSubmitButton: handleSubmit(onSubmit),
      }}
      onChangeShow={onChangeShow}
      overlayBlur
      className={className}
      isForm
      hasPadding
    >
      <FlexContainer
        as="form"
        flexDirection="column"
        rowGap="1.6"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input
          autoComplete="off"
          name="hidden"
          type="text"
          style={{ display: 'none' }}
        />
        <Label fontSize="14" htmlFor="email">
          {t('Email')}
        </Label>
        <Input
          testId="user-input-email"
          id="input-email"
          autoFocus
          {...register(EMAIL, { required: true })}
          placeholder="example@example.com"
          errorLabel={errors.email?.message && t(errors.email.message)}
        />

        <Label fontSize="14" htmlFor="firstName">
          {t('Firt Name')}
        </Label>
        <Input
          testId="user-input-firstName"
          id="firstName"
          {...register(FIRSTNAME)}
          errorLabel={errors.firstName?.message && t(errors.firstName.message)}
        />

        <Label fontSize="14" htmlFor="lastName">
          {t('Last Name')}
        </Label>
        <Input
          testId="user-input-lastName"
          id="lastName"
          {...register(LASTNAME)}
          errorLabel={errors.lastName?.message && t(errors.lastName.message)}
        />

        <Label fontSize="14" htmlFor="password">
          {t('Password')}
        </Label>

        <Input
          testId="user-input-password"
          id="input-password"
          type="password"
          autoComplete="new-password"
          {...register(PASSWORD)}
          errorLabel={errors.password?.message && t(errors.password.message)}
        />
      </FlexContainer>
    </ModalCreateUserStyles>
  );
};
