import { ChangeEvent, useEffect, useState, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  ModalCreateUser,
  TabSearchingHeader,
  UsersTable,
} from '@cs/components';
import { useBlockUser, useScrollPosition } from '@cs/hooks';
import { useLayout } from '@cs/providers';
import { MaxItemsPag, RoutesName } from '@cs/state/constants';
import { SCOPES, User, listUsersDto } from '@cs/state/model';
import { insertUser } from '@cs/state/mutations';
import { listUsers } from '@cs/state/queries';
import { CardBase, useToast } from '@facephi/ui-react';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEmptyState } from '../../hooks/useEmptyState';

export const Users = () => {
  const { t } = useTranslation();
  const { setTitle, setGoBackRoute } = useLayout();
  const routeParams = useParams();
  const { toastManager } = useToast();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>('');

  const getQueryVariables = () => ({
    clientId: routeParams.id,
    name: `%${userName}%`,
    limit: MaxItemsPag,
  });

  const { onHandleBlockUser } = useBlockUser(getQueryVariables);

  const { data, loading, error, refetch, fetchMore } = useQuery<listUsersDto>(
    listUsers,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        ...getQueryVariables(),
        offset: 0,
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  );
  const [createUser] = useMutation(insertUser);

  const { scrollPosition } = useScrollPosition(
    RoutesName.userId,
    data?.clients_by_pk.users,
  );

  const onCreate = async (user: User) => {
    await createUser({
      variables: {
        user: {
          email: user.email,
          client_id: routeParams.id,
          first_name: user.firstName,
          last_name: user.lastName,
          password: user.password,
          metadata: user.metadata,
        },
      },
      update: (cache, { data: { createUser } }) => {
        if (createUser && data) {
          const queryData = cloneDeep(data);
          queryData.clients_by_pk.users.unshift(createUser.user);

          cache.writeQuery({
            query: listUsers,
            variables: getQueryVariables(),
            data: queryData,
          });
        }
      },
      onError: (error) => {
        toastManager({
          type: 'error',
          message: error.message,
          duration: 3000,
          testId: 'toast-error-user',
        });
      },
      onCompleted: () => {
        toastManager({
          type: 'success',
          message: t('A new user has successfully added '),
          duration: 3000,
          testId: 'toast-create-user',
        });
      },
    });

    setShowModal(false);
  };

  useEffect(() => {
    setTitle(t('Users list'));
    setGoBackRoute(RoutesName.clients);

    return () => setGoBackRoute(undefined);
  }, []);

  const handleInputChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setUserName(value);
  };

  const forceUpdateQuery = () => {
    refetch(getQueryVariables());
  };

  const hasMoreItems = useMemo(
    () =>
      !!data &&
      data?.clients_by_pk?.users?.length <
        data?.clients_by_pk.users_aggregate.aggregate.count,
    [data],
  );

  const handlePagination = async (): Promise<boolean> => {
    if (hasMoreItems && !loading) {
      await fetchMore({
        variables: {
          offset: data?.clients_by_pk.users.length,
        },
      });
    }

    return Promise.resolve(true);
  };

  const { emptyState } = useEmptyState({
    data: data?.clients_by_pk.users,
    hasError: Boolean(error),
    isLoading: loading,
    isFilter: Boolean(userName),
    onRetry: forceUpdateQuery,
  });

  return (
    <>
      <CardBase flex="1" flexDirection="column">
        <TabSearchingHeader
          onCreate={() => setShowModal(true)}
          onChange={handleInputChange}
          permissionsCreate={[SCOPES.canWriteUsers]}
        />

        <UsersTable
          users={data?.clients_by_pk.users}
          loading={loading}
          fetchMore={handlePagination}
          hasMore={hasMoreItems}
          scrollPosition={scrollPosition}
          emptyStateData={emptyState}
          onBlock={onHandleBlockUser}
        />
      </CardBase>

      {showModal && (
        <ModalCreateUser
          show={showModal}
          onSave={onCreate}
          onChangeShow={(showModalCreateUser) =>
            setShowModal(showModalCreateUser)
          }
        />
      )}
    </>
  );
};
