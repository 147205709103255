import { useEffect, useState } from 'react';
import { useApi } from '../../hooks';

type IProps = {
  id?: string;
  alt: string;
  defaultImage?: string;
};

export const Image = ({ id, alt, defaultImage }: IProps) => {
  const [image, setImage] = useState<string | null>();

  const { getFile } = useApi();

  useEffect(() => {
    if (id) {
      getFile(id).then((value) => {
        if (value) {
          const reader = new FileReader();
          reader.readAsDataURL(value);
          reader.onloadend = function () {
            const base64data = reader.result;
            setImage(base64data as string);
          };
        }
      });
    }
  }, [id]);

  return <img src={image ? image : defaultImage} alt={alt} />;
};
