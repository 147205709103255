import { gql } from '@apollo/client';
import { userFragment, userTenantFragment } from '../fragments';

export const getUser = gql`
  ${userFragment}
  query getUser($userId: uuid!) {
    users_by_pk(id: $userId) {
      ...usersFields
    }
  }
`;

export const listUsers = gql`
  ${userTenantFragment}
  ${userFragment}
  query getUsers($clientId: uuid!, $name: String, $offset: Int, $limit: Int) {
    clients_by_pk(id: $clientId) {
      users(
        where: {
          _or: [
            { email: { _ilike: $name } }
            { first_name: { _ilike: $name } }
            { last_name: { _ilike: $name } }
          ]
        }
        offset: $offset
        limit: $limit
        order_by: { created_at: desc }
      ) {
        ...usersFields
        users_tenants(where: { tenant: { deleted_at: { _is_null: true } } }) {
          id
          tenant {
            id
            name
          }
        }
      }
      users_aggregate(
        where: {
          _or: [
            { email: { _ilike: $name } }
            { first_name: { _ilike: $name } }
            { last_name: { _ilike: $name } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const getUsersByClient = gql`
  query getUsersByClient($clientId: uuid) {
    users(where: { client_id: { _eq: $clientId } }) {
      id
      email
    }
  }
`;

export const getTenantsByUser = gql`
  ${userTenantFragment}
  query getTenantsUser($userId: uuid!) {
    users_tenants(
      where: {
        user_id: { _eq: $userId }
        tenant: { deleted_at: { _is_null: true } }
      }
    ) {
      id
      active: default
      tenant {
        ...userTenantFields
      }
    }
  }
`;
