import { useEffect, useRef, useState } from 'react';
import { throttle } from 'lodash';

const getScrollPosition = () =>
  window.scrollY || document.documentElement.scrollTop;

const getScrollTop = () =>
  (document.scrollingElement || document.documentElement).scrollTop;

const getScrollBottom = () =>
  Math.abs(
    window.innerHeight + getScrollPosition() - document.body.scrollHeight,
  );

const getScrollPercentage = () => {
  const scrollPosition = getScrollPosition();
  const scrollHeight =
    document.documentElement.scrollHeight -
    document.documentElement.clientHeight;
  const scrollTop = scrollPosition / scrollHeight || 0;

  return scrollTop * 100;
};

export function useScroll(delayedThrottle = 100) {
  const scrollThrottle = useRef(delayedThrottle);
  const [scroll, setScroll] = useState({
    hasScrolled: false,
    top: 0,
    bottom: 0,
    percentage: 0,
  });

  useEffect(() => {
    scrollThrottle.current = delayedThrottle;
  }, [delayedThrottle]);

  useEffect(() => {
    const updateScroll = () => {
      setScroll({
        hasScrolled: true,
        top: getScrollTop(),
        bottom: getScrollBottom(),
        percentage: getScrollPercentage(),
      });
    };
    const scrollListener = throttle(updateScroll, scrollThrottle.current);

    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  return scroll;
}
