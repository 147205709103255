import { InMemoryCache, makeVar } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { DEFAULT_TIMEZONE } from '@cs/state/constants';

export const flowIdVar = makeVar<string>('');
export const timeZoneVar = makeVar<string>(DEFAULT_TIMEZONE);

export const operationsSearch = makeVar({});
export const usernameSearch = makeVar<string>('');

export const cache = new InMemoryCache({
  typePolicies: {
    clients: {
      fields: {
        users: offsetLimitPagination(['where', 'limit']),
        tenants: offsetLimitPagination(['where', 'limit']),
      },
    },
    Query: {
      fields: {
        clients: offsetLimitPagination(['where', 'limit']),
        licenses: offsetLimitPagination(['where', 'limit']),
        clients_services: offsetLimitPagination(['where', 'limit']),
      },
    },
  },
});
