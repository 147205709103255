import { ReactElement, useMemo } from 'react';
import { useDelete } from '@cs/hooks';
import { RoutesName } from '@cs/state/constants';
import { SCOPES, TableProps, Tenant } from '@cs/state/model';
import { removeTenant, undoRemoveTenant } from '@cs/state/mutations';
import {
  ButtonIcon,
  Label,
  OptionMenu,
  OptionMenuItem,
  PositionX,
  TableLegacy as TableSelection,
} from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { PermissionsGate } from '../permissions';
import { TablePanelButton } from './Styles';

type TenantTableData = {
  name: string;
  tenantId: string;
  userTenants: ReactElement;
};

type Props = TableProps & {
  tenants?: Tenant[];
};

export const TenantTable = ({
  tenants,
  loading,
  hasMore,
  fetchMore,
  emptyStateData,
  scrollPosition,
}: Props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const routeParams = useParams();
  const { handleDelete } = useDelete(removeTenant, undoRemoveTenant, 'tenants');

  const columns = useMemo(
    () => [
      {
        Header: t('Name'),
        accessor: 'name',
        maxWidth: 200,
      },
      {
        Header: t('ID'),
        accessor: 'tenantId',
        maxWidth: 300,
      },
      {
        Header: t('Users'),
        accessor: 'tenantUsers',
      },
      {
        Header: t('Action'),
        accessor: 'action',
        maxWidth: 60,
        Cell: (props: CellProps<TenantTableData>) => {
          return (
            <OptionMenu
              hidePanelOnClick
              widthAuto
              renderItem={<ButtonIcon variant="text" iconName="ListPlus" />}
              positionX={PositionX.end}
              testId="option-menu-tenant"
            >
              <OptionMenuItem
                testId="button-edit-tenant"
                iconName="MagnifyingGlassPlus"
                onClick={() =>
                  navigate(
                    generatePath(RoutesName.tenantId, {
                      clientId: routeParams.id,
                      tenantId: props.row.original.tenantId,
                    }),
                  )
                }
              >
                {t('Edit')}
              </OptionMenuItem>

              <PermissionsGate scopes={[SCOPES.canDeleteLicense]}>
                <OptionMenuItem
                  testId="button-delete-tenant"
                  onClick={() => handleDelete(props.row.original.tenantId)}
                  iconName="Trash"
                  color="error"
                >
                  {t('Delete')}
                </OptionMenuItem>
              </PermissionsGate>
            </OptionMenu>
          );
        },
      },
    ],
    [],
  );

  const tableData = useMemo(() => {
    return (
      tenants?.map((tenant: Tenant) => ({
        tenantId: tenant.id,
        name: tenant.name,
        tenantUsers: tenant.users_tenants?.length ? (
          <OptionMenu
            renderItem={
              <TablePanelButton
                variant="text"
                iconName="CaretDown"
                iconPosition="right"
              >
                {`${tenant.users_tenants.length} ${t('Users')}`}{' '}
              </TablePanelButton>
            }
          >
            {tenant.users_tenants.map((tenantUser) =>
              tenantUser.user ? (
                <OptionMenuItem key={tenantUser.user.id}>
                  {tenantUser.user.name}
                </OptionMenuItem>
              ) : null,
            )}
          </OptionMenu>
        ) : (
          <Label fontSize="14">{t('No users')}</Label>
        ),
        action: '',
      })) || []
    );
  }, [tenants]);

  return (
    <TableSelection
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      columns={columns as any}
      data={tableData}
      loading={loading}
      hasMore={hasMore}
      fetchMore={fetchMore}
      scrollPosition={scrollPosition}
      emptyStateData={emptyStateData}
      locale={i18n.language}
    />
  );
};
