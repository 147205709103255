import { ReactElement, useMemo } from 'react';
import { useDelete } from '@cs/hooks';
import { RoutesName } from '@cs/state/constants';
import { TableProps, UserListDto } from '@cs/state/model';
import { removeUser, undoRemoveUser } from '@cs/state/mutations';
import {
  ButtonIcon,
  Label,
  OptionMenu,
  OptionMenuItem,
  PositionX,
  Status,
  TableLegacy as TableSelection,
} from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { TablePanelButton } from '../tenants/Styles';

type UserTableData = {
  name: string;
  userId: string;
  avatarId: string;
  userTenants: ReactElement;
  active: boolean;
};

type Props = TableProps & {
  users?: UserListDto[];
  onBlock(id: string, active: boolean): void;
};

export const UsersTable = ({
  users,
  loading,
  hasMore,
  fetchMore,
  emptyStateData,
  scrollPosition,
  onBlock,
}: Props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const routeParams = useParams();
  const { handleDelete } = useDelete(removeUser, undoRemoveUser, 'users');

  const columns = useMemo(
    () => [
      {
        Header: t('Email (ID)'),
        accessor: 'email',
        maxWidth: 400,
      },
      {
        Header: t('Tenants'),
        accessor: 'userTenants',
      },
      {
        Header: t('Active'),
        accessor: 'active',
        maxWidth: 150,
        Cell: (props: CellProps<UserTableData>) => {
          return (
            <Status
              status={props.row.original.active ? 'SUCCEEDED' : 'DENIED'}
              testId="user-active"
            />
          );
        },
      },
      {
        Header: t('Action'),
        accessor: 'action',
        maxWidth: 60,
        Cell: (props: CellProps<UserTableData>) => {
          return (
            <OptionMenu
              hidePanelOnClick
              renderItem={<ButtonIcon variant="text" iconName="ListPlus" />}
              positionX={PositionX.end}
              testId="option-menu-user"
            >
              <OptionMenuItem
                testId="button-edit-user"
                iconName="MagnifyingGlassPlus"
                onClick={(event) => {
                  navigate(
                    generatePath(RoutesName.userId, {
                      clientId: routeParams.id,
                      userId: props.row.original.userId,
                    }),
                  );
                  event.stopPropagation();
                }}
              >
                {t('Edit')}
              </OptionMenuItem>

              <OptionMenuItem
                testId="button-block-user"
                iconName={
                  props.row.original.active ? 'Prohibit' : 'CheckCircle'
                }
                onClick={(event) => {
                  onBlock(props.row.original.userId, props.row.original.active);
                  event.stopPropagation();
                }}
              >
                {t(props.row.original.active ? 'Block' : 'Unblock')}
              </OptionMenuItem>
              <OptionMenuItem
                testId="button-delete-user"
                onClick={(event) => {
                  handleDelete(props.row.original.userId);
                  event.stopPropagation();
                }}
                iconName="Trash"
                color="error"
              >
                {t('Delete')}
              </OptionMenuItem>
            </OptionMenu>
          );
        },
      },
    ],
    [],
  );

  const tableData = useMemo(() => {
    return (
      users?.map((user: UserListDto) => ({
        userId: user.id,
        email: user.email,
        name: user.name,
        userTenants: user.users_tenants?.length ? (
          <OptionMenu
            renderItem={
              <TablePanelButton
                variant="text"
                iconName="CaretDown"
                iconPosition="right"
              >
                {`${user.users_tenants.length} ${t('Tenants')}`}
              </TablePanelButton>
            }
          >
            {user.users_tenants.map((userTenant) =>
              userTenant.tenant ? (
                <OptionMenuItem key={userTenant.tenant.id}>
                  {userTenant.tenant?.name}
                </OptionMenuItem>
              ) : null,
            )}
          </OptionMenu>
        ) : (
          <Label fontSize="14">{t('No tenants')}</Label>
        ),
        tenant: '',
        active: user.active,
        avatarId: user.avatar || '',
      })) || []
    );
  }, [users]);

  return (
    <TableSelection
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      columns={columns as any}
      data={tableData}
      loading={loading}
      hasMore={hasMore}
      fetchMore={fetchMore}
      scrollPosition={scrollPosition}
      emptyStateData={emptyStateData}
      onClickCell={(data) => {
        navigate(
          generatePath(RoutesName.userId, {
            clientId: routeParams.id,
            userId: data.userId,
          }),
        );
      }}
      locale={i18n.language}
    />
  );
};
