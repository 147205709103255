import { ChangeEvent, useEffect, useState, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  ModalCreateTenant,
  TabSearchingHeader,
  TenantTable,
} from '@cs/components';
import { useScrollPosition } from '@cs/hooks';
import { useLayout } from '@cs/providers';
import { MaxItemsPag, RoutesName } from '@cs/state/constants';
import { SCOPES, Tenant } from '@cs/state/model';
import { insertTenant } from '@cs/state/mutations';
import { getTenants } from '@cs/state/queries';
import { CardBase, useToast } from '@facephi/ui-react';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEmptyState } from '../../hooks/useEmptyState';

type listTenantsDto = {
  clients_by_pk: {
    tenants: Tenant[];
    tenants_aggregate: {
      aggregate: {
        count: number;
      };
    };
  };
};

export const Tenants = () => {
  const routeParams = useParams();
  const { t } = useTranslation();
  const { setTitle, setGoBackRoute } = useLayout();
  const { toastManager } = useToast();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [tenantName, setTenantName] = useState<string>('');

  const getQueryVariables = () => ({
    clientId: routeParams.id,
    name: `%${tenantName}%`,
    limit: MaxItemsPag,
  });

  const { data, loading, error, refetch, fetchMore } = useQuery<listTenantsDto>(
    getTenants,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        ...getQueryVariables(),
        offset: 0,
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  );

  const tenants = useMemo(
    () => (data ? data?.clients_by_pk.tenants : []),
    [data],
  );

  const [createTenant] = useMutation(insertTenant);

  const { scrollPosition } = useScrollPosition(
    RoutesName.tenantId,
    data?.clients_by_pk.tenants,
  );

  const onCreate = async (tenant: Tenant) => {
    await createTenant({
      variables: {
        clientId: routeParams.id,
        name: tenant.name.toLowerCase(),
        timezone: '',
        address: '',
      },
      update: (cache, { data: { insert_tenants_one } }) => {
        if (insert_tenants_one && data) {
          const queryData = cloneDeep(data);
          queryData.clients_by_pk.tenants.unshift(insert_tenants_one);

          cache.writeQuery({
            query: getTenants,
            variables: getQueryVariables(),
            data: queryData,
          });
        }
      },
      onCompleted: () => {
        toastManager({
          type: 'success',
          message: t('Tenant successfully created'),
          duration: 3000,
          testId: 'toast-create-tenant',
        });
      },
    });

    setShowModal(false);
  };

  useEffect(() => {
    setTitle(t('Tenants list'));
    setGoBackRoute(RoutesName.clients);

    return () => setGoBackRoute(undefined);
  }, []);

  const forceUpdateQuery = () => {
    refetch(getQueryVariables());
  };

  const handleInputChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setTenantName(value);
  };

  const hasMoreItems = (): boolean =>
    !!data &&
    tenants.length < data?.clients_by_pk.tenants_aggregate.aggregate.count;

  const handlePagination = async (): Promise<boolean> => {
    if (hasMoreItems() && !loading) {
      await fetchMore({
        variables: {
          offset: tenants.length,
        },
      });
    }

    return Promise.resolve(true);
  };

  const { emptyState } = useEmptyState({
    data: tenants,
    hasError: Boolean(error),
    isLoading: loading,
    isFilter: Boolean(tenantName.length),
    onRetry: forceUpdateQuery,
  });

  return (
    <>
      <CardBase flexDirection="column" flex="1">
        <TabSearchingHeader
          onCreate={() => setShowModal(true)}
          onChange={handleInputChange}
          permissionsCreate={[SCOPES.canWriteTenants]}
        />

        <TenantTable
          tenants={tenants}
          loading={loading}
          fetchMore={handlePagination}
          hasMore={hasMoreItems()}
          scrollPosition={scrollPosition}
          emptyStateData={emptyState}
        />
      </CardBase>

      {showModal && (
        <ModalCreateTenant
          show={showModal}
          onSave={onCreate}
          onChangeShow={(showModalCreateTenant) =>
            setShowModal(showModalCreateTenant)
          }
        />
      )}
    </>
  );
};
