import { gql } from '@apollo/client';
import { userFragment, userTenantFragment } from '../fragments';

export const insertUser = gql`
  ${userTenantFragment}
  ${userFragment}
  mutation createUser($user: UserInput!) {
    createUser(user: $user) {
      id
      user {
        ...usersFields
      }
      users_tenants {
        id
        tenant {
          ...userTenantFields
        }
      }
    }
  }
`;

export const removeUser = gql`
  mutation deleteUser($id: uuid!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: { deleted_at: "now()" }) {
      id
    }
  }
`;

export const undoRemoveUser = gql`
  ${userTenantFragment}
  ${userFragment}
  mutation undoRemoveUser($id: uuid!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: { deleted_at: null }) {
      ...usersFields
      users_tenants {
        id
        tenant {
          ...userTenantFields
        }
      }
    }
  }
`;

export const modifyUser = gql`
  ${userFragment}
  mutation editUser($id: uuid!, $user: EditUserInput!) {
    editUser(id: $id, user: $user) {
      id
      name
      email
      avatar
      active
      lastName
      firstName
      metadata
      timezone
      language
    }
  }
`;

export const removeUserTenants = gql`
  mutation removeUserTenants($tenantsId: [uuid!]!, $userId: uuid!) {
    delete_users_tenants(
      where: { tenant_id: { _in: $tenantsId }, user_id: { _eq: $userId } }
    ) {
      returning {
        id
      }
    }
  }
`;

export const addUserTenants = gql`
  mutation addUserTenants($tenantsId: [uuid!]!, $userId: uuid!) {
    addUserTenants(tenants: $tenantsId, userId: $userId) {
      id
    }
  }
`;

export const verifyEmail = gql`
  mutation verifyEmail($userId: uuid!) {
    verifyEmail(userId: $userId)
  }
`;

export const makeDefaultTenant = gql`
  mutation makeDefaultTenant($tenantId: uuid!) {
    makeDefaultTenant(tenantId: $tenantId) {
      id
    }
  }
`;
